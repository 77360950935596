<template>
  <div class="block">
    <div class="columns marginless">
      <div class="section-header label-field column">
        Vendor-submitted contact information
      </div>
    </div>

    <div class="columns marginless">
      <div class="label-field column is-3">
        Payee name
      </div>
      <div class="column is-9">
        {{ payeeName }}
      </div>
    </div>

    <div class="columns marginless">
      <div class="label-field column is-3">
        Payee address
      </div>
      <div
        class="column is-9"
        v-html="payeeAddress"
      />
    </div>

    <div class="columns marginless">
      <div class="label-field column is-3">
        Payee phone number
      </div>
      <div
        class="column is-9"
        v-html="payeeContactPhoneNumber"
      />
    </div>

    <div class="columns marginless">
      <div class="label-field column is-3">
        Employee name
      </div>
      <div class="column is-9">
        {{ employeeName }}
      </div>
    </div>

    <hr>

  </div>
</template>

<script>

export default {
  name: "AchRequestVendorSubmitted",
  computed: {
    payeeContactPhoneNumber() {
      let value;
      if (this.$store.state.ach.achData) {
        value = this.$store.state.ach.achData.payeeContactPhoneNumber;
      }
      return value;
    },
    payeeAddress() {
      let value;
      if (this.$store.state.ach.achData) {
        value = this.$store.state.ach.achData.payeeContactStreetAddress1 + '<br>';
        if (this.$store.state.ach.achData.payeeContactStreetAddress2) {
          value += this.$store.state.ach.achData.payeeContactStreetAddress2 + '<br>';
        }
        value += this.$store.state.ach.achData.payeeContactAddressCity + ', ';
        value += this.$store.state.ach.achData.payeeContactAddressState + ' ';
        value += this.$store.state.ach.achData.payeeContactAddressZipCode;
      }
      return value;
    },
    payeeName() {
      let value;
      if (this.$store.state.ach.achData) {
        value = this.$store.state.ach.achData.payeeName;
      }
      return value;
    },
    employeeName() {
      let value;
      if (this.$store.state.ach.achData) {
        value = this.$store.state.ach.achData.payeeContactFirstName + ' ' + this.$store.state.ach.achData.payeeContactLastName;
      }
      return value;
    },
    dateSubmitted() {
      let value;
      if (this.$store.state.ach.achData) {
        value = this.$dayjs(this.$store.state.ach.achData.submissionDate).format('M/DD/YYYY');
      }
      return value;
    },
  },
};
</script>
