<template>
  <div class="block">

    <div class="columns marginless">
      <div class="label-field column is-3">
        Vendor name
      </div>
      <div class="column is-9">
        {{ vendorName }}
      </div>
    </div>

    <!-- moved Payee name to Vendor-submitted information section
    <div class="columns marginless">
      <div class="label-field column is-3">
        Payee name
      </div>
      <div class="column is-9">
        {{ payeeName }}
      </div>
    </div>
    -->

    <div class="columns marginless">
      <div class="label-field column is-3">
        Payee EIN/SSN
      </div>
      <div class="column is-9">
        {{ einOrSSN }}
      </div>
    </div>

    <div class="columns marginless">
      <div class="label-field column is-3">
        Request ID
      </div>
      <div class="column is-9">
        {{ achId }}
      </div>
    </div>

    <div class="columns marginless">
      <div class="label-field column is-3">
        Request type
      </div>
      <div class="column is-9">
        {{ requestType }}
      </div>
    </div>

    <div class="columns marginless">
      <div class="label-field column is-3">
        Status
      </div>
      <div class="column is-9">
        {{ statusText }}
      </div>
    </div>

    <div class="columns marginless">
      <div class="label-field column is-3">
        Submitted on
      </div>
      <div class="column is-9">
        {{ dateSubmitted }}
      </div>
    </div>

    <div class="columns marginless">
      <div class="label-field column is-3">
        Days since last action
      </div>
      <div class="column is-9">
        {{ daysPending }}
      </div>
    </div>

    <div class="columns marginless">
      <div class="label-field column is-3">
        Current department
      </div>
      <div class="column is-9">
        {{ requestDepartmentName }}
      </div>
    </div>

    <div class="columns marginless">
      <div class="label-field column is-3">
        Previous department
      </div>
      <div class="column is-9">
        {{ previousDepartmentName }}
      </div>
    </div>

    <!-- <div
      v-if="status === 'Rejected-pending Finance Review'"
      class="columns marginless"
    >
      <div class="label-field column is-3">
        Reason
      </div>
      <div class="column is-9">
        Is this necessary? it is already in the audit trail.
      </div>
    </div> -->

    <hr>

  </div>
</template>

<script>

import StatusMixin from '@/pages/ach-request/StatusMixin';

export default {
  name: "AchRequestOverview",
  mixins: [ StatusMixin ],
  props: {
    achId: {
      type: String,
      default: '',
    },
  },
  computed: {
    department() {
      let value;
      if (this.$store.state.ach.achData && this.$store.state.ach.achData.department) {
        value = this.$store.state.ach.achData.department.name;
      }
      return value;
    },
    daysPending() {
      let value;
      if (this.$store.state.ach.achData) {
        if (this.$store.state.ach.achData.daysSinceLastAction < 0) {
          value = 'NA';
        } else {
          value = this.$store.state.ach.achData.daysSinceLastAction;
        }
      }
      return value;
    },
    requestType() {
      let values = {
        'NewEnrollment': 'New enrollment',
        'ChangeEnrollment': 'Change to existing enrollment',
        'CancelEnrollment': 'Cancel (stop) enrollment',
      };

      let value;
      if (this.$store.state.ach.achData) {
        value = values[this.$store.state.ach.achData.requestType];
      }
      return value;
    },
    vendorName() {
      let value;
      if (this.$store.state.ach.achData && this.$store.state.ach.achData.vendorData) {
        value = this.$store.state.ach.achData.vendorData.name;
      }
      return value;
    },
    payeeName() {
      let value;
      if (this.$store.state.ach.achData) {
        value = this.$store.state.ach.achData.payeeName;
      }
      return value;
    },
    einOrSSN() {
      let value;
      if (this.$store.state.ach.achData && this.$store.state.ach.achData.einOrSSN) {
        value = '*****' + this.$store.state.ach.achData.einOrSSN.substr(-4);
      }
      return value;
    },
    payeeContactPhoneNumber() {
      let value;
      if (this.$store.state.ach.achData) {
        value = this.$store.state.ach.achData.payeeContactPhoneNumber;
      }
      return value;
    },
    payeeAddress() {
      let value;
      if (this.$store.state.ach.achData) {
        value = this.$store.state.ach.achData.payeeContactStreetAddress1 + '<br>';
        if (this.$store.state.ach.achData.payeeContactStreetAddress2) {
          value += this.$store.state.ach.achData.payeeContactStreetAddress2 + '<br>';
        }
        value += this.$store.state.ach.achData.payeeContactAddressCity + ', ';
        value += this.$store.state.ach.achData.payeeContactAddressState + ' ';
        value += this.$store.state.ach.achData.payeeContactAddressZipCode;
      }
      return value;
    },
    employeeName() {
      let value;
      if (this.$store.state.ach.achData) {
        value = this.$store.state.ach.achData.payeeContactFirstName + ' ' + this.$store.state.ach.achData.payeeContactLastName;
      }
      return value;
    },
    dateSubmitted() {
      let value;
      if (this.$store.state.ach.achData) {
        value = this.$dayjs(this.$store.state.ach.achData.submissionDate).format('M/DD/YYYY');
      }
      return value;
    },
    requestDepartmentName() {
      let value;
      if (this.department) {
        value = this.department;
      } else {
        value = "Finance";
      }
      return value;
    },
    previousDepartmentName() {
      let value;
      if (this.$store.state.ach.achData && this.$store.state.ach.achData.previousDepartment) {
        value = this.$store.state.ach.achData.previousDepartment.name;
      } else if (this.$store.state.ach.achData && this.$store.state.ach.achData.status === 'Waiting Authorization') {
        value = 'NA';
      } else {
        value = 'Finance';
      }
      return value;
    },
    status() {
      let value;
      if (this.$store.state.ach.achData) {
        value = this.$store.state.ach.achData.status;
      }
      return value;
    },
    statusText() {
      let value;
      if (this.$store.state.ach.achData) {
        value = this.statusKey[this.$store.state.ach.achData.status];
      }
      return value;
    },
  },
};
</script>
