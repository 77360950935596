<template>
  <div class="block">
    <div class="columns marginless">
      <div class="section-header label-field column">
        Audit trail
      </div>
    </div>

    <div class="timeline is-left">
      <div class="timeline-header">
        <span class="tag is-light">Now</span>
      </div>
      <div
        v-for="(action, index) in auditTrailList"
        :key="index"
        class="timeline-item"
        :class="timelineClass(index, action)"
      >
        <div
          class="timeline-marker is-icon"
          :class="timelineClass(index, action)"
        >
          <i
            v-if="isFinal(index)"
            class="fas fa-stop"
          />
          <i
            v-else
            class="fab fa-font-awesome-flag"
          />
        </div>
        <div class="timeline-content">
          <p class="heading">
            {{ fixDate(action.createdAt) }}:
          </p>
          <div v-html="createMessage(action)" />
        </div>

      </div>
      <div class="timeline-header">
        <span class="tag is-success">
          <i class="far fa-calendar-alt" />
        </span>
      </div>
    </div>

  </div>
</template>

<script>

import { parseISO } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';


export default {
  name: "AchRequestAuditTrail",
  data() {
    return {
      showingTimeline: [],
      showingMore: false,
      actionValue: {
        'Creation': 'is-info',
        'TransferToDepartment': 'is-info',
        'TransferToFinance': 'is-info',
        'ApprovedByDepartment': 'is-success',
        'DeniedByDepartment': 'is-danger',
        'EmailSent': 'is-info',
        'DeptBankView': 'is-info',
        'FinanceBankView': 'is-info',
        'BankVerifySuccess': 'is-success',
        'BankVerifyFailure': 'is-danger',
        'AchFinalApproval': 'is-success',
        'AchFinalDenial': 'is-danger',
        'AchSentBackToDept': 'is-warning',
        'VoidedCheckView': 'is-info',
        'DeletedByAdmin': 'is-black',
      },
    };
  },
  computed: {
    auditTrailList() {
      let value = [];
      if (this.$store.state.ach.achData) {
        for (let i = 0; i < this.$store.state.ach.achData.auditTrailList.length; i++) {
          value.push(this.$store.state.ach.achData.auditTrailList[i]);
        }
      }
      return value;
    },
  },
  methods: {
    fixDate(value) {
      return format(parseISO(value), 'MM/dd/yyyy hh:mm aa');
    },
    createMessage(action) {
      console.log('AuditTrail.vue createMessage is running, action:', action);
      let deptTo;
      if (action.departmentToId) {
        if (action.departmentToData) {
          deptTo = action.departmentToData.name;
        } else if (action.departmentToId == '00000000-0000-0000-0000-000000000000') {
          deptTo = 'Finance';
        } else {
          deptTo = '[to department missing]';
        }
      } else {
        deptTo = '[to department missing]';
      }

      let deptFrom;
      if (action.departmentFromData) {
        deptFrom = action.departmentFromData.name;
      } else {
        deptFrom = 'Finance';
      }

      let note;
      if (action.notes) {
        note = action.notes;
      } else {
        note = 'N/A';
      }

      let reason;
      if (action.reason) {
        reason = action.reason;
      } else {
        reason = 'N/A';
      }

      let userDept;
      if (action.userDepartmentData) {
        userDept = action.userDepartmentData.name;
      } else {
        userDept = 'Finance';
      }

      let values = {
        'Creation': '<b>' + action.user.emailAddress + '</b> submitted a new ACH request to ' + deptTo,
        'TransferToDepartment': '<b>' + action.user.emailAddress + '</b> from ' + userDept + ' transferred the request from ' + deptFrom + ' to ' + deptTo,
        'TransferToFinance': '<b>' + action.user.emailAddress + '</b> from ' + userDept + ' transferred the request from ' + deptFrom + ' to ' + deptTo,
        'ApprovedByDepartment': '<b>' + action.user.emailAddress + '</b> from ' + userDept + ' approved the request',
        'DeniedByDepartment': '<b>' + action.user.emailAddress + '</b> from ' + userDept + ' denied the request. Reason: ' + action.reason,
        'EmailSent': 'Request has been pending ' + deptFrom + ' for ' + 5 + ' days. An automated reminder email has been sent to ' + action.user.emailAddress,
        'DeptBankView': '<b>' + action.user.emailAddress + '</b> from ' + userDept + ' viewed the request\'s bank information',
        'FinanceBankView': '<b>' + action.user.emailAddress + '</b> from ' + userDept + ' viewed the request\'s bank information',
        'BankVerifySuccess': '<b>' + action.user.emailAddress + '</b> from ' + userDept + ' confirmed the request\'s bank information',
        'BankVerifyFailure': '<b>' + action.user.emailAddress + '</b> from ' + userDept + ' attempted to enter the request\'s bank account number and failed',
        'AchFinalApproval': '<b>' + action.user.emailAddress + '</b> from ' + userDept + ' entered the request into FAMIS',
        'AchFinalDenial': '<b>' + action.user.emailAddress + '</b> from ' + userDept + ' denied the request.  Note: ' + note,
        'AchSentBackToDept': '<b>' + action.user.emailAddress + '</b> from ' + userDept + ' sent the request to ' + deptTo + ' for review.  Note: ' + note,
        'VoidedCheckView': '<b>' + action.user.emailAddress + '</b> from ' + userDept + ' viewed the voided check/bank letter',
        'DeletedByAdmin': '<b>' + action.user.emailAddress + '</b> from ' + userDept + ' deleted the request.  Reason: ' + reason,
      };

      let actionString = values[action.actionType];
      return actionString;
    },
    isFinal(index) {
      return index === 0;
      // && (this.theCase.status === 'Resolved' || this.theCase.status === 'Closed');
    },
    isFirst(index) {
      return index === this.auditTrailList.length - 1;
    },
    timelineClass(index, action) {
      let value;
      if (action.actionType) {
        value = this.actionValue[action.actionType];
      }
      return value;
    },
  },
};

</script>
